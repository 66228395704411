import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/auth/actions';

const Navbar = ({ drawerWidth, handleDrawerToggle }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar
      position='fixed'
      sx={{
        width: { lg: `calc(100% - ${drawerWidth}px)` },
        ml: { lg: `${drawerWidth}px` },
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderBottom: '1px solid #DDE1E5',
      }}
    >
      <Toolbar color='#454655'>
        <IconButton
          color='#1C1C38'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          color='#454655'
          variant='h6'
          noWrap
          component='div'
          sx={{ display: { lg: 'none' }, flexGrow: 1 }}
        >
          <Link href='/' color='inherit' underline='none'>
            <b>REPRISE</b>
          </Link>
        </Typography>

        <Box sx={{ width: { lg: '100%' }, textAlign: { lg: 'end' } }}>
          <Button
            size='large'
            id='user-button'
            disableRipple
            aria-controls={open ? 'user-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              textTransform: 'none',
              color: '#454655',
              focusVisible: 'none',
              fontSize: '1.1rem',
            }}
            endIcon={<MoreVertIcon />}
          >
            {user?.full_name}
          </Button>
          <Menu
            id='user-menu'
            aria-labelledby='user-button'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem component={RouterLink} to='/cms/profile'>
              <ListItemIcon>
                <PersonIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logout());
                handleClose();
              }}
            >
              <ListItemIcon>
                <LogoutIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
