const list = {
  header: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
    {
      id: 'inverted',
      label: 'Inverted',
      align: 'left',
      switch: true,
    },
    {
      id: 'flipped',
      label: 'Flipped',
      align: 'left',
      switch: true,
    },
    {
      id: 'featured',
      label: 'Featured',
      align: 'left',
      switch: true,
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
    },
    {
      type: 'image',
      name: 'outter_image',
      label: 'Outter Image',
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: true,
      rich: true,
    },

    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
    {
      type: 'switch',
      name: 'featured',
      label: 'Featured',
      required: true,
    },
    {
      type: 'switch',
      name: 'inverted',
      label: 'Inverted',
      required: true,
    },
    {
      type: 'switch',
      name: 'flipped',
      label: 'Flipped',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'image',
      name: 'outter_image',
      label: 'Outter Image',
      required: true,
    },
    {
      type: 'image',
      name: 'icon',
      label: 'Icon',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: true,
      rich: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'image',
      name: 'outter_image',
      label: 'Outter Image',
      required: true,
    },
    {
      type: 'image',
      name: 'icon',
      label: 'Icon',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'subtitle',
      label: 'Subtitle',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: true,
      rich: true,
    },
  ],
};

const order = {
  fields: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
    {
      type: 'text',
      name: 'text',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
