import { configureStore } from '@reduxjs/toolkit';

import adminsReducer from './features/admins/slice';
import permissionsReducer from './features/permissions/slice';
import rolesReducer from './features/roles/slice';
import bannersReducer from './features/banners/slice';
import googleMarketingReducer from './features/google-marketing/slice';
import googleMarketingItemsReducer from './features/google-marketing-items/slice';
import solutionsReducer from './features/solutions/slice';
import clientsReducer from './features/clients/slice';
import caseStudiesReducer from './features/case-studies/slice';
import partnersReducer from './features/partners/slice';
import servicesReducer from './features/services/slice';
import expertiseReducer from './features/expertise/slice';
import aboutUsReducer from './features/about-us/slice';
import joinUsReducer from './features/join-us/slice';
import insightsReducer from './features/insights/slice';
import privacyPolicyReducer from './features/privacy-policy/slice';
import leadersReducer from './features/leaders/slice';
import homeInformativeReducer from './features/home-informative/slice';
import homeItemsReducer from './features/home-items/slice';
import authReducer from './features/auth/slice';
import alertsReducer from './features/alerts/slice';
import sidebarReducer from './features/sidebar/slice';
import smtpReducer from './features/smtp/slice';
import supportReducer from './features/support/slice';
import socialReducer from './features/social/slice';
import LicensingRequirementsReducer from './features/licensing-requirements/slice';
import findusReducer from './features/findus/slice';
import contactUsReducer from './features/contact-us/slice';

export const store = configureStore({
  // Add the slices to the store so they can be accessed by any components on the app
  reducer: {
    admins: adminsReducer,
    alerts: alertsReducer,
    permissions: permissionsReducer,
    roles: rolesReducer,
    auth: authReducer,
    sidebar: sidebarReducer,
    smtp: smtpReducer,
    'social media': socialReducer,
    'google marketing': googleMarketingReducer,
    'google marketing items': googleMarketingItemsReducer,
    'find us': findusReducer,
    'join us': joinUsReducer,
    'contact us': contactUsReducer,
    'licensing requirements': LicensingRequirementsReducer,
    support: supportReducer,
    banners: bannersReducer,
    clients: clientsReducer,
    solutions: solutionsReducer,
    'case studies': caseStudiesReducer,
    services: servicesReducer,
    expertise: expertiseReducer,
    partners: partnersReducer,
    insights: insightsReducer,
    leaders: leadersReducer,
    'home informative': homeInformativeReducer,
    'about us': aboutUsReducer,
    'privacy policy': privacyPolicyReducer,
    'home items': homeItemsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
