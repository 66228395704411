const list = {
  header: [
    {
      id: 'title',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },

    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
    },
  ],
};

const order = {
  fields: [
    {
      id: 'title',
      label: 'Title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
