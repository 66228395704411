const edit = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      rich: true,
      required: true,
    },
    {
      type: 'text',
      name: 'text_1',
      label: 'Text 1',
      rich: true,
      required: true,
    },
    {
      type: 'text',
      name: 'text_2',
      label: 'Text 2',
      required: true,
    },
    {
      type: 'image',
      name: 'badge_1',
      label: 'Badge 1',
      required: false,
    },
    {
      type: 'image',
      name: 'badge_2',
      label: 'Badge 2',
      required: false,
    },
    {
      type: 'image',
      name: 'badge_3',
      label: 'Badge 3',
      required: false,
    },
  ],
  main: true,
  breadcrumb: 'Google Marketing',
  title: 'Google Marketing',
};

const data = { edit };

export default data;
