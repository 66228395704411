import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
} from '@mui/material';
import { authorized } from '../../utils/utils';

import Icon from './Icon';

import { useSelector } from 'react-redux';

const Sidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {
  const location = useLocation();
  const [open, setOpen] = useState(null);

  const sidebar = useSelector((state) => state.sidebar.list);
  const user = useSelector((state) => state.auth.user);

  const handleClick = (label) => {
    setOpen({ ...open, [label]: !open[label] });
  };

  useEffect(() => {
    let res = {};
    sidebar.forEach((item) => {
      if (item.route)
        res[item.label] = location.pathname.split('/')[2]
          ? location.pathname.split('/')[2] === item.route
          : false;
      else {
        if (item.open) res[item.label] = true;
        else
          item.children.forEach((child) => {
            if (!res[item.label])
              res[item.label] = location.pathname.split('/')[2]
                ? location.pathname.split('/')[2] === child.route
                : false;
          });
      }
    });
    setOpen(res);
  }, [sidebar]);

  const drawer = user?.Permissions && (
    <>
      <Toolbar>
        <Typography
          align='center'
          width='100%'
          display='none'
          color='#454655'
          variant='h6'
          noWrap
          component='div'
          sx={{ display: { lg: 'block' } }}
        >
          <Link href='#' color='inherit' underline='none'>
            <b>REPRISE</b>
          </Link>
        </Typography>
      </Toolbar>
      <Divider />
      {open && (
        <List
          subheader={
            <ListSubheader component='div' id='navigation'>
              Navigation
            </ListSubheader>
          }
          color='#454655'
        >
          {sidebar
            .filter((s) =>
              s.children
                ? s.children.filter((c) =>
                    authorized(user?.Permissions, c.route.toLowerCase()),
                  ).length > 0
                : authorized(user?.Permissions, s.route.toLowerCase()),
            )
            .map((item, i) => (
              <div key={i}>
                <ListItem disablePadding>
                  {(item.route &&
                    authorized(
                      user?.Permissions,
                      item.route.toLowerCase(),
                      item.permission ? item.permission : 'list',
                    ) && (
                      <ListItemButton
                        component={RouterLink}
                        to={item.route}
                        selected={
                          location.pathname.split('/')[2]
                            ? location.pathname.split('/')[2] === item.route
                            : false
                        }
                      >
                        {item.icon && (
                          <ListItemIcon>
                            <Icon
                              type={item.icon}
                              color={item.color ? item.color : 'primary'}
                            />
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primaryTypographyProps={{ fontSize: '0.8rem' }}
                          primary={item.label}
                        />
                      </ListItemButton>
                    )) || (
                    <ListItemButton onClick={() => handleClick(item.label)}>
                      {item.icon && (
                        <ListItemIcon>
                          <Icon
                            type={item.icon}
                            color={item.color ? item.color : 'primary'}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primaryTypographyProps={{ fontSize: '0.8rem' }}
                        primary={item.label}
                      />
                      {open[item.label] ? (
                        <Icon type='ExpandLess' />
                      ) : (
                        <Icon type='NavigateBefore' />
                      )}
                    </ListItemButton>
                  )}
                </ListItem>
                {item.children && (
                  <Collapse in={open[item.label]} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      {item.children
                        .filter((item) =>
                          authorized(
                            user?.Permissions,
                            item.route.toLowerCase(),
                            item.permission ? item.permission : 'list',
                          ),
                        )
                        .map((child, j) => (
                          <ListItemButton
                            key={j}
                            sx={{ pl: 4 }}
                            component={RouterLink}
                            to={child.route}
                            selected={
                              location.pathname.split('/')[2]
                                ? location.pathname
                                    .split('/')[2]
                                    .replace('%20', ' ') === child.route
                                : false
                            }
                          >
                            {child.icon && (
                              <ListItemIcon>
                                <Icon
                                  type={child.icon}
                                  color={child.color ? child.color : 'primary'}
                                />
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primaryTypographyProps={{ fontSize: '0.8rem' }}
                              primary={child.label}
                            />
                          </ListItemButton>
                        ))}
                    </List>
                  </Collapse>
                )}
              </div>
            ))}
        </List>
      )}
    </>
  );

  return (
    <>
      <Box
        component='nav'
        sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}
        aria-label='navigation'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default Sidebar;
