const list = {
  header: [
    {
      id: 'title',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: false,
    },
    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: true,
      rich: true,
    },
    {
      type: 'select',
      async: true,
      name: 'expertise',
      label: 'Expertise',
      multiple: true,
      required: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'text',
      label: 'Text',
      required: true,
      rich: true,
    },
    {
      type: 'select',
      async: true,
      multiple: true,
      name: 'Expertises',
      route: 'expertise',
      label: 'Expertise',
      required: true,
    },
  ],
};

const order = {
  fields: [
    {
      id: 'title',
      label: 'title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
    {
      type: 'text',
      name: 'text',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
