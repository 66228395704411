const list = {
  header: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'date',
      name: 'date',
      label: 'Date',
      required: false,
    },
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'tag',
      label: 'Tag',
      required: false,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
      rich: true,
    },
    {
      type: 'image',
      name: 'thumbnail',
      label: 'Thumbnail',
      required: false,
    },
    {
      type: 'text',
      name: 'badge_title',
      label: 'Badge Title',
      required: false,
    },
    {
      type: 'image',
      name: 'badge',
      label: 'Badge',
      required: false,
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },
    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'date',
      name: 'date',
      label: 'Date',
      required: false,
    },
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'tag',
      label: 'Tag',
      required: false,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
      rich: true,
    },
    {
      type: 'image',
      name: 'thumbnail',
      label: 'Thumbnail',
      required: true,
    },
    {
      type: 'text',
      name: 'badge_title',
      label: 'Badge Title',
      required: false,
    },
    {
      type: 'image',
      name: 'badge',
      label: 'Badge',
      required: false,
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'date',
      name: 'date',
      label: 'Date',
      required: false,
    },
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'tag',
      label: 'Tag',
      required: false,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      required: true,
      rich: true,
    },
    {
      type: 'image',
      name: 'thumbnail',
      label: 'Thumbnail',
      required: true,
    },
    {
      type: 'text',
      name: 'badge_title',
      label: 'Badge Title',
      required: false,
    },
    {
      type: 'image',
      name: 'badge',
      label: 'Badge',
      required: false,
    },
    {
      type: 'file',
      name: 'media',
      label: 'Media',
      required: false,
    },
  ],
};

const order = {
  fields: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
    {
      type: 'text',
      name: 'body',
    },
    {
      type: 'text',
      name: 'date',
    },
    {
      type: 'text',
      name: 'tag',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
