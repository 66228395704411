const edit = {
  fields: [
    {
      type: 'text',
      name: 'title',
      label: 'Title',
    },
    {
      type: 'text',
      name: 'text',
      label: 'text',
    },
    {
      type: 'text',
      name: 'link',
      label: 'Link',
    },
  ],
  main: true,
  breadcrumb: 'Join Us Settings',
  title: 'Join Us Settings',
};

const data = { edit };

export default data;
