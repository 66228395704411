const list = {
  header: [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'number',
      label: 'Mobile Number',
      align: 'left',
    },
    {
      id: 'email',
      label: 'Email',
      align: 'left',
    },
  ],
  permission: 'list',
  actions: ['show'],
};
const show = {
  fields: [
    {
      type: 'text',
      name: 'name',
      label: 'Name',
      required: true,
    },
    {
      type: 'text',
      name: 'number',
      label: 'Number',
      required: true,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Email',
      required: true,
    },
    {
      type: 'text',
      name: 'requirements',
      label: 'Requirements',
      required: true,
    },
  ],
  permission: 'show',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'name',
    },
    {
      type: 'text',
      name: 'number',
    },
    {
      type: 'text',
      name: 'email',
    },
  ],
};

const data = { list, show, search };

export default data;
