const edit = {
  fields: [
    {
      type: 'text',
      name: 'name',
      label: 'Name',
    },
    {
      type: 'text',
      name: 'link',
      label: 'Share Link',
    },
    {
      type: 'text',
      name: 'map_link',
      label: 'Map Link',
    },
  ],
  main: true,
  breadcrumb: 'Find Us Settings',
  title: 'Find Us Settings',
};

const data = { edit };

export default data;
