import { createSlice } from '@reduxjs/toolkit';

// State on app load and refresh
const initialState = {
  list: [
    {
      label: 'Adminstration',
      icon: 'Shield',
      children: [
        {
          label: 'Admins',
          icon: 'SupervisorAccount',
          route: 'admins',
        },
        {
          label: 'Roles',
          icon: 'AddModerator',
          route: 'roles',
        },
        {
          label: 'Permissions',
          icon: 'AdminPanelSettings',
          route: 'permissions',
        },
      ],
    },
    {
      label: 'Banners',
      icon: 'ViewCarousel',
      route: 'banners',
    },
    {
      label: 'Home',
      icon: 'Home',
      children: [
        {
          label: 'Informative',
          icon: 'Article',
          route: 'home informative',
          permission: 'form',
        },
        {
          label: 'Items',
          icon: 'Category',
          route: 'home items',
        },
      ],
    },
    {
      label: 'Google Marketing',
      icon: 'Google',
      children: [
        {
          label: 'Google Marketing',
          icon: 'Article',
          route: 'google marketing',
          permission: 'form',
        },
        {
          label: 'Items',
          icon: 'Category',
          route: 'google marketing items',
        },
      ],
    },
    {
      label: 'Services',
      icon: 'Inventory',
      children: [
        {
          label: 'Services List',
          icon: 'List',
          route: 'services',
        },
        {
          label: 'Expertise',
          icon: 'List',
          route: 'expertise',
        },
        {
          label: 'Solutions',
          icon: 'List',
          route: 'solutions',
        },
        {
          label: 'Clients',
          icon: 'List',
          route: 'clients',
        },
        {
          label: 'Case Studies',
          icon: 'List',
          route: 'case studies',
        },
      ],
    },
    {
      label: 'Insights',
      icon: 'Newspaper',
      route: 'insights',
    },
    {
      label: 'Partners',
      icon: 'Handshake',
      route: 'partners',
    },
    {
      label: 'Leaders',
      icon: 'Person',
      route: 'leaders',
    },
    {
      label: 'Contact Us',
      icon: 'ContactSupport',
      route: 'contact us',
    },
    {
      label: 'Licensing Requirements',
      icon: 'ContactSupport',
      route: 'licensing requirements',
    },
    {
      label: 'About Us',
      icon: 'Info',
      route: 'about us',
      permission: 'form',
    },
    {
      label: 'Privacy Policy',
      icon: 'ContactSupport',
      route: 'privacy policy',
      permission: 'form',
    },
    {
      label: 'Join Us',
      icon: 'Info',
      route: 'join us',
      permission: 'form',
    },
    {
      label: 'Settings',
      icon: 'Settings',
      children: [
        {
          label: 'SMTP',
          icon: 'Mail',
          route: 'smtp',
          permission: 'form',
        },

        {
          label: 'Find Us',
          icon: 'Directions',
          route: 'find us',
          permission: 'form',
        },
        {
          label: 'Support',
          icon: 'SupportAgent',
          route: 'support',
          permission: 'form',
        },
        {
          label: 'Social Media',
          icon: 'Share',
          route: 'social media',
          permission: 'form',
        },
      ],
    },
  ], // will hold the list of items
};

// Create the Users Slice
const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {},
});

// Export the slice reducer
export default sidebarSlice.reducer;
