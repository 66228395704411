const list = {
  header: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'Title',
      orderBy: 'title',
      align: 'left',
    },
    {
      id: 'publish',
      label: 'Publish',
      align: 'left',
      switch: true,
    },
  ],
  permission: 'list',
  actions: ['bulk remove', 'show', 'delete', 'update'],
};
const show = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      rich: true,
      required: true,
    },
    {
      type: 'switch',
      name: 'publish',
      label: 'Published',
      required: true,
    },
  ],
  permission: 'show',
};
const create = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      rich: true,
      required: true,
    },
  ],
};
const edit = {
  fields: [
    {
      type: 'image',
      name: 'image',
      label: 'Image',
      required: true,
    },
    {
      type: 'text',
      name: 'title',
      label: 'Title',
      required: true,
    },
    {
      type: 'text',
      name: 'body',
      label: 'Body',
      rich: true,
      required: true,
    },
  ],
};

const order = {
  fields: [
    {
      id: 'image',
      display: true,
      label: 'Image',
      align: 'left',
    },
    {
      id: 'title',
      label: 'Title',
      align: 'left',
    },
  ],
  permission: 'order',
};

const search = {
  fields: [
    {
      type: 'text',
      name: 'title',
    },
  ],
};

const data = { list, create, edit, show, order, search };

export default data;
