import Main from './components/pages/Main';

import Box from '@mui/material/Box';
import { Route, Routes } from 'react-router-dom';
import List from './components/pages/default/List';
import Show from './components/pages/default/Show';
import Form from './components/pages/default/Form';
import Order from './components/pages/default/Order';

// Custom Pages
import RolesForm from './components/pages/roles/Form';

import AdminsPage from './components/pages/admins';
import PermissionsPage from './components/pages/permissions';
import BannersPage from './components/pages/banners';
import LeadersPage from './components/pages/leaders';
import PartnersPage from './components/pages/partners';
import ServicesPage from './components/pages/services';
import ClientsPage from './components/pages/clients';
import SolutionsPage from './components/pages/solutions';
import CaseStudiesPage from './components/pages/case-studies';
import LicensingRequirementsPage from './components/pages/licensing-requirements';
import ExpertisePage from './components/pages/expertise';
import InsightsPage from './components/pages/insights';
import PrivacyPolicyPage from './components/pages/privacy-policy';
import ContactUsPage from './components/pages/contact-us';
import FindUsPage from './components/pages/findus';
import JoinUsPage from './components/pages/join-us';
import SupportPage from './components/pages/support';
import SocialPage from './components/pages/social';
import HomeInformativePage from './components/pages/home-informative';
import GoogleMarketingPage from './components/pages/google-marketing';
import GoogleMarketingItemsPage from './components/pages/google-marketing-items';
import AboutUsPage from './components/pages/about-us';
import HomeItemsPage from './components/pages/home-items';
import SMTPPage from './components/pages/smtp';
import RolesPage from './components/pages/roles';
import Alerts from './components/layouts/Alerts';
import { createTheme, ThemeProvider } from '@mui/material';
import Login from './components/pages/Login';
import AuthRoute from './components/layouts/AuthRoute';
import NotFound from './components/layouts/NotFound';
import Profile from './components/pages/auth/Profile';
import NotAuthorized from './components/layouts/NotAuthorized';

const THEME = createTheme({
  typography: {
    fontFamily: `Open Sans,sans-serif`,
  },
});

const App = ({ props }) => {
  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: 'flex' }} color='black'>
        <Alerts />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/cms/login' element={<Login />} />
          <Route
            path='/cms'
            element={
              <AuthRoute redirectTo='login'>
                <Main props={props} />
              </AuthRoute>
            }
          >
            {/* Users page */}
            <Route path='profile' element={<Profile />} exact />

            <Route path='admins' element={<AdminsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='permissions' element={<PermissionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='home items' element={<HomeItemsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route
              path='google marketing items'
              element={<GoogleMarketingItemsPage />}
              exact
            >
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='partners' element={<PartnersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>
            <Route path='solutions' element={<SolutionsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>
            <Route path='clients' element={<ClientsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='case studies' element={<CaseStudiesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='services' element={<ServicesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='expertise' element={<ExpertisePage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='contact us' element={<ContactUsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            <Route
              path='licensing requirements'
              element={<LicensingRequirementsPage />}
              exact
            >
              <Route path='' element={<List />} exact />
              <Route path=':id' element={<Show />} exact />
            </Route>

            <Route path='leaders' element={<LeadersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='insights' element={<InsightsPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path='order' element={<Order />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='banners' element={<BannersPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<Form type='create' />} exact />
              <Route path=':id' element={<Show />} exact />
              <Route path=':id/edit' element={<Form type='edit' />} exact />
            </Route>

            <Route path='find us' element={<FindUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>
            <Route path='join us' element={<JoinUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='smtp' element={<SMTPPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='social media' element={<SocialPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route
              path='home informative'
              element={<HomeInformativePage />}
              exact
            >
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route
              path='google marketing'
              element={<GoogleMarketingPage />}
              exact
            >
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='about us' element={<AboutUsPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='privacy policy' element={<PrivacyPolicyPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='support' element={<SupportPage />} exact>
              <Route path='' element={<Form type='edit' />} exact />
            </Route>

            <Route path='roles' element={<RolesPage />} exact>
              <Route path='' element={<List />} exact />
              <Route path='add' element={<RolesForm type='create' />} exact />
              <Route
                path=':id/edit'
                element={<RolesForm type='edit' />}
                exact
              />
            </Route>
          </Route>
          <Route path='unauthorized' element={<NotAuthorized />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
