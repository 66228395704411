import { Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Footer from '../layouts/Footer'
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, login } from '../../features/auth/actions';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, user, is_authenticated } = useSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [visibility, setVisibility] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ data: formData, navigate }))
    }


    useEffect(() => {
        if (loading && !is_authenticated) {
            dispatch(getUser())
        }
        if (is_authenticated) {
            navigate('/cms')
        }
    }, [loading, user, is_authenticated, dispatch, navigate])

    return (
        <Grid
            component="main"
            backgroundColor="#F6F7FB"
            height="100vh"
            width="100%"
            container
            alignItems='start'
            justifyContent='center'
        >
            <Grid component={Stack} item xs={10} sm={6} md={5} lg={2} mt={2} sx={{ backgroundColor: "#F6F7FB", borderRadius: 0, height: '100%' }} alignItems="center" justifyContent='start' direction="column">
                <Typography variant='h1' fontSize="1.625rem" fontWeight={600} lineHeight={1.5} color="primary" textAlign='center' marginTop={5}>Welcome to <b>Prestige</b> Dashboard!</Typography>
                <Paper component='form' sx={{ width: '100%', borderRadius: 0, padding: { lg: "3rem", xs: "2rem" }, marginY: '70px', boxShadow: '0 2px 5px 2px rgba(0, 0, 0, 0.1)' }} onSubmit={onSubmit}>
                    <Typography variant='h3' fontSize="1.0625rem" fontWeight={600} lineHeight={1.5} textAlign="center" color="#454655" marginBottom='2rem'>Login with your credentials</Typography>
                    <div className="form-control">
                        <input className="form-input" type="email" required={true} name='email' value={formData.email} onChange={(e) => onChange(e)} placeholder="Email" />
                    </div>
                    <div className="form-control">
                        <div className="form-input-password">
                            <input type={!visibility ? "password" : "text"} required={true} name='password' id='password' value={formData.password} onChange={(e) => onChange(e)} placeholder="Password" />
                            <IconButton
                                edge="start"
                                onClick={() => { setVisibility(!visibility) }}
                                size="small"
                                sx={{ padding: 0 }}
                                disableRipple
                            >

                                {!visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </div>
                    </div>
                    <Stack direction="row" width="100%" spacing={1} alignItems="center" justifyContent='center'>
                        <Button type="submit" variant="contained" sx={{ borderRadius: 0, padding: '8px 48px', textTransform: 'none', marginTop: 2 }}>
                            <b>Login</b>
                        </Button>
                    </Stack>
                </Paper>
                <Footer direction={'column'} justifyContent={'center'} />
            </Grid>
        </Grid >
    )
}

export default Login