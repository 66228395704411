const edit = {
  fields: [
    {
      type: 'text',
      name: 'text_1',
      label: 'Text 1',
      required: true,
    },
    {
      type: 'text',
      name: 'text_2',
      label: 'Text 2',
      required: true,
    },
    {
      type: 'text',
      name: 'partners_title',
      label: 'Partners Title',
      required: true,
    },
    {
      type: 'text',
      name: 'partners',
      label: 'Partners',
      rich: true,
      required: true,
    },
    {
      type: 'number',
      name: 'countries',
      label: 'Countries',
      required: true,
    },
    {
      type: 'number',
      name: 'offices',
      label: 'Offices',
      required: true,
    },
    {
      type: 'number',
      name: 'team',
      label: 'Team Members',
      required: true,
    },
  ],
  main: true,
  breadcrumb: 'About Us',
  title: 'About Us',
};

const data = { edit };

export default data;
