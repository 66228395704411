
const edit = {
    fields: [
        {
            type: "email",
            name: "email",
            label: "Support Email",
        }, {
            type: "tel",
            name: "phone",
            label: "Support Tel",
        },
    ],
    main: true,
    breadcrumb: 'Support Settings',
    title: 'Support Settings',
};

const data = { edit }

export default data;